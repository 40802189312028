import React from 'react'
import Navbar from '../../components/navbar'
import BlogMain from '../../../static/images/insurance.jpg'



export default function roleofActuaries() {
  return (
    <div>
       <Navbar/>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover"src={BlogMain} alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        {/* <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div> */}
        <h2 class="mb-6 text-3xl lg:text-4xl text-indigo-800 font-bold font-heading">Parametric Insurance – Role of Actuaries
</h2>
        <div class="flex items-center justify-center">
          {/* <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div> */}
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-600 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs text-center">Published On May 13, 2022 | Raunak Jha</p>
          </div>
        </div>
      </div>
      <div class="max-w-3xl mx-auto -mb-6 ">
        <p class="mb-6  text-md lg:text-lg text-gray-600">Parametric insurance also known as Index based insurance, is an emerging and non-traditional way of transferring risks.
              <ul>
                
                <li> Traditionally, Non-life insurance indemnifies the insured for the actual losses that have occurred due to specified perils. In other words, this is to restore the position of the insured after the loss event has affected the insured as to the level before the loss event.</li>
              
              </ul>
        
Parametric insurance, simply, is not directly concerned with the actual losses that have occurred; instead it tries to make up for the losses based on a particular parameter or an index which closely best reflects the losses that can occur.
It is an agreement to make a payment upon the occurrence of a triggering event based on the parameter, and as such is detached of underlying actual losses.</p>
     
        <p class="mb-6 font-bold text-md lg:text-lg text-gray-600">Now let’s understand the parameter (or index) and triggers

</p>
        <p class="mb-6  text-md lg:text-lg text-gray-600">The event parameters for such insurance are generally weather events or natural catastrophes. Examples include flood, earthquake, cyclone, rainfall, temperature, relative humidity etc. The selected event parameter should be highly correlated with the losses covered
Trigger events are particular measurements of the event parameters that determine the threshold, breaching which the insurance payment is made.</p>
        <p class="mb-6  text-md lg:text-lg text-gray-600">Let’s take a simple example to inhale the basic concept – Suppose the tomato crop production is highly correlated (or impacted) by the scarcity of rainfall (event parameter). During a particular period of time, say one month, the required amount of rainfall for tomato production is 120mm (threshold). In case, the amount of rainfall less than 120mm during the month (trigger event), threshold is breached and a payment is made for the amount of rain shortfall. It is assumed that this shortfall in rain will affect the tomato crop. Even if the actual tomato crop is not completely affected, the payment is made. Thus it is detached from the actual losses.</p>
      
        <p class="mb-6 font-bold text-md lg:text-lg text-gray-600">Comparison with indemnity based insurance</p>
        <p class="mb-6 text-md lg:text-lg text-gray-600">The above stated concept of index based crop insurance differs from the indemnity (or yield) based crop insurance.

Firstly a threshold yield is determined based on past actual yield data, which determines the optimum crop yield that should be produced. Actual yield is then observed using crop cutting experiments (actual crop yields from various sample places).
If the observed seasonal area-yield per hectare of the insured crop for the defined geographical area (the insurance unit) falls below a specific threshold yield, all insured farmers growing that crop in the defined area will receive the same claim payment per unit of sum insured.
Thus this considers the actual estimated crop losses.
The determination of threshold yield based on scarce past data, delay and high expenses involved in crop cutting experiments and operational management are some of the main reasons that make it comparatively less attractive than Index based crop insurance.</p>


<p class="mb-6 font-bold text-md lg:text-lg text-gray-600">Deep dive into the general structure of a parametric insurance (crop) in India </p>

<p class="mb-6 text-md lg:text-lg text-gray-600">Following are the examples of the crops covered with the related weather index for the crop under weather based crop insurance scheme (WBCIS) in India.</p>

<p class="mb-6 font-bold text-md lg:text-lg text-gray-600">Coverage of Crops</p>
<div>
<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> Food Crops (Cereals, Millets and Pulses) </li>
  <li> Oilseeds </li>
  <li> Commercial / Horticultural crop </li>
</ul>
</div>
<div>
<p class="mb-6 font-bold text-md lg:text-lg text-gray-600">Weather Perils to be covered </p>

<p class="mb-6 text-md lg:text-lg text-gray-600">Following are the major weather perils, which are deemed to cause “Adverse Weather Incidence”, leading to crop loss, and have demonstrated correlation with quantifiable yield losses of crop –   </p>


</div>
<div>
<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> Rainfall – Deficit Rainfall, Excess rainfall, Unseasonal Rainfall, Rainy days, Dry-spell, Dry days </li>
  <li> Temperature– High temperature (heat), Low temperature </li>
  <li> Relative Humidity </li>
  <li> Wind Speed </li>
  <li> A combination of the above </li>
  <li> Hailstorm, cloud-burst </li>
</ul>

<p class="mb-6 text-md lg:text-lg text-gray-600">Following describes the channel of operation of weather based crop insurance scheme (WBCIS) in India as an example.

<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> Central government issues notice for the acceptance of the WBCIS by state governments with the operational guidelines related to it. </li>
  <li> In case any state opts for WBCIS, it invites insurance companies to steer the whole program as implementing agency (IA).</li>
  <li> Different districts will be classified into groups for different corps to be covered in the district and the insurance companies will be involved in price (premium) biding for the particular crops covered. Lowest bidder will be assigned to a particular group of districts to operate as a sole implementing agency for the scheme.</li>
  <li> There would be other intermediaries who smooth the flow between farmers and the IA. These include banks and common service centers that help farmers to get to the WBCIS access. </li>

</ul>
</p>

<p class="mb-6 text-md lg:text-lg text-gray-600">Role of Actuaries </p>

<p class="mb-6 text-md lg:text-lg text-gray-600">A General explanation –

Firstly, for any crop in any particular geographical area (district or sub-district level) the “term sheets” are prepared.

A Term Sheet generally represents – </p>

<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> the weather event parameter impacting the crop,
</li>
  <li> the thresholds for determining the trigger event and
</li>
  <li> The payment schedule in case of breaching the thresholds.
</li>
  <li> Term sheets (except payment schedule part) are prepared by the agro-scientists of agriculture departments based on the features of a particular crop, its relevance to the weather conditions and the particular geographical area</li>
<li> This can also be prepared with the help of reinsurers that has significant exposures to the crop profiles with the help of actuaries and agro experts.</li>
<li> Actuaries play the role of determining the payment schedule included in the term sheet.</li>
</ul>
<p class="mb-6 text-md lg:text-lg text-gray-600">Following is an example of term sheet for Tomato with Deficit Rainfall as weather index.</p>
<p class="mb-6 text-md lg:text-lg text-gray-600">Above shown term sheet explains – </p>

<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> If actual rainfall is greater than 120mm during a month then there would be no payments.

</li>
  <li> If rainfall is less than 120mm and greater than 80mm then Rs.120 per mm of deficit below 120mm is the payout.

</li>
  <li> If rainfall goes below 80mm then due to higher adverse impact on the tomato crop higher payment will be made i.e. Rs.152.5 for each mm below 80mm.

</li>
  <li> There would be no payment below 0mm. (In other cases this might be 30mm representing no coverage for rainfall going below 30mm and the coverage stops.)
</li>
<li> In total the maximum payout should not exceed Rs.17000.
</li>
<li> In second table it shows the payout in case of consecutive less rainfall days (Dry). If there is no rainfall for at least 12 days consecutively there will be payouts as specified. Rainfall less than 2.5mm is considered to be dry days for tomato crop.
</li>
</ul>


<p class="mb-6 text-md lg:text-lg text-gray-600">Now to determine the actual rainfall during a period of time we need real time various weather data – measurements of rainfall, humidity, wind speed, temperature etc. in a particular geographical area.</p>

<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> This is obtained from weather data providers. Government also establishes its own infrastructure to get these real time data.


</li>
  <li> Weather data is generally obtained through “Weather Stations”. These are technological equipment that records the measurements of rainfall, humidity, wind speed, temperature etc. These are installed at various areas of a district. Generally, these are installed at sub levels called “insurance units” such as at village level. This is because, the more number of weather stations that are there closely in an area it can gather more appropriate weather data at minute level and a better estimation of the payout can be made. It depends on the range of coverage of the weather stations (generally 5KM).


</li>

</ul>
<p class="mb-6 text-md lg:text-lg text-gray-600">At an insurance unit (village level say), one can determine the actual weather information and compare it with the triggers mentioned in the term sheet to determine the payouts.</p>
<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> All the insured farmers in that village will be paid based on the breach of triggers (say shortfall in rain), whether or not the actual losses has occurred to any particular farmer’s crop.
</li>
  <li> Now comes the existence of “basis risk” – This represents the deviation of payments from the actual losses. There could be higher or lower payouts than the losses actually happened to the crop.
</li>

<li>
To reduce these basis risks, appropriate weather station at narrow geographical level should be installed. Actuaries also try to reduce these basis risks while considering pricing.

</li>
</ul>

<p class="mb-6 text-md lg:text-lg text-gray-600">Now the determination of payment schedule invites the actuaries in bigger role. A very brief explanation is as below (Detailed Pricing Approach will be shared in a separate article) –
</p>

<ul className='mb-6 text-md lg:text-lg text-gray-600 list-disc ml-6'>
  <li> Actuaries use large amounts of weather data from various sources to determine the payouts in the event of triggers.</li>
  <li> Two approaches used are – Standalone and Portfolio ratemaking.</li>

<li>In short, weather data are de-trended over past years and over geographical regions. Historical burning cost is calculated and Credibility theory is used to smooth the basic rates. Other loading are then applied.
</li>
</ul>

<p class="mb-16 text-md lg:text-lg pb-24 text-gray-600">Actuaries play a role in determining appropriate reinsurance structure for extreme level of losses. Thus, actuaries are involved in product designing of index based insurance (e.g. crop), pricing the product, helps maintaining and managing the required funds as reserves and reducing basis risks.
</p>
</div>

      </div>
    </div>
  </div>
</section>



    </div>
  )
}
